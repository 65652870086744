import { Icon } from "@iconify/react";
import React from "react";
import "./styles.scss";
import resume from "assets/file/Resume-Agun-Adesola.pdf";
import graphql from "assets/img/graphql.png";
import js from "assets/img/javascript.png";
import angular from "assets/img/angular.png";
import react from "assets/img/react.png";
import htmlCSS from "assets/img/html-css.png";
import git from "assets/img/git.png";
import picture2 from "assets/img/picture2.png";
import picture1 from "assets/img/picture1.png";
import picture4 from "assets/img/picture4.png";
import picture3 from "assets/img/picture3.jpg";
import picture5 from "assets/img/picture5.JPG";
import picture6 from "assets/img/picture6.JPG";
import picture7 from "assets/img/picture7.JPG";
import picture8 from "assets/img/picture8.png";
import { Footer } from "components/footer";
import logo from "assets/img/logo.svg";
import { Link } from "react-router-dom";
import { Contact } from "components/contact";

const spareTime = (
  pic1: string,
  pic2: any,
  pic3: any,
  title: string,
  desc: string
) => {
  return (
    <div className="row mb-5 spare-time-box">
      <div className="col-lg-6 col-md-8 col-12 mb-xs-5 mb-md-auto mt-auto">
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
      <div className="col-lg-6 col-md-10 col-12 mb-5">
        <div className="spare-time-imgs">
          <img src={pic2} alt="" className="sm-img1" />
          <img src={pic3} alt="" className="sm-img2" />
          <img src={pic1} alt="" className="big-img" />
        </div>
      </div>
    </div>
  );
};
export const AboutPage = () => {
  return (
    <section className="container about-page">
      <Contact isHero={false} isHome={false} />
      <div className="d-flex justify-between">
        <Link to="/">
          <img src={logo} alt="" className="logo" />
        </Link>
        <Link to="/" className="font-bold" style={{ fontSize: "2.8rem" }}>
          <Icon icon="emojione-monotone:back-arrow" />
        </Link>
      </div>

      <header className="mb-4">
        <h4 className="mb-4">About Me 😁</h4>
        <h1 className="mb-4">
          I Develop Web and Mobile <br /> Applications.
        </h1>
        <div className="d-flex flex-wrap top-links">
          <a href="mailto:agunadesola@gmail.com" className="mr-2">
            <span className="mr-2 text-green">
              <Icon icon="feather:mail" />
            </span>
            Send me a mail
          </a>
          <a
            href="https://www.linkedin.com/in/agun-adesola/"
            target="_blank"
            rel="noreferrer"
            className="mr-2"
          >
            <span className="mr-2">
              <Icon icon="twemoji:joystick" />
            </span>
            LinkedIn
          </a>
          <a
            href="https://github.com/treasurechic"
            target="_blank"
            rel="noreferrer"
            className="mr-2"
          >
            <span className="mr-2">
              <Icon icon="bytesize:github" />
            </span>
            Github
          </a>
          <a href={resume} className="mr-2" download>
            My Resume
          </a>
        </div>
        <hr />
      </header>
      <section className="mb-5">
        <p className="desc">
          I am a digital nomad that specialize in the frontend development,
          making the digital world exiciting and interactive for people all over
          the world. I enjoy remote working and experiencing diverse cultures. I
          have worked in a variety of industries and mentored a few young
          developers.
        </p>
        <p className="desc">
          I make use of modern technology and uses best practice to create
          user-friendly, responsive and highly efficient web and mobile
          applications. <br />I have excellent knowledge of{" "}
          <span className="text-green">
            Javascript; ReactJs, React Native, AngularJs, GraphQL, Typescript,
            HTML, CSS , SCSS, tailwind and bootstrap.
          </span>{" "}
          I also have very good knowledge of GIT version control and{" "}
          <span className="text-green">exceptional</span> time management skill.
        </p>
        <div className="stack-grid my-5">
          <div className="item item1">
            <img src={js} alt="" className="object-contain px-4" />
          </div>
          <div className="item item2 p-0 p-md-5">
            <img src={react} alt="" />
          </div>
          <div className="item item3">
            <img src={angular} alt="" />
          </div>
          <div className="item item4 p-5">
            <img src={htmlCSS} alt="" className="p-0 p-md-5" />
          </div>
          <div className="item item5 p-5">
            <img src={graphql} alt="" className="p-0 p-md-5" />
          </div>
          <div className="item item6 p-0 p-md-5">
            <img src={git} alt="" className="object-left" />
          </div>
        </div>
      </section>
      <section className="my-5">
        <h4 style={{ paddingTop: "7rem", paddingBottom: "5rem" }}>
          In my spare time, I get involve in things like...
        </h4>
        {spareTime(
          picture3,
          picture5,
          picture7,
          "Public Speaking on Technology.",
          "I love to share my insights and journey of becoming a developer with others and also help facilate events that solve community problems with technology."
        )}
        {spareTime(
          picture1,
          picture4,
          picture2,
          "Public Teaching.",
          "I attend events where I get to share my knowledge on software developmnt so as to help the attendees become a better developer"
        )}
        {spareTime(
          picture8,
          picture6,
          picture6,
          "Online Tutorial",
          "I also indulge in creating videos on Youtube that provides impactful and useful knowledge on web development. The channel's name is Code With Chic."
        )}
      </section>
      <Footer />
    </section>
  );
};

import React from "react";
import "./styles.scss";
import bixoli from "assets/img/portfolio/bixoli2.png";
import bxCoach from "assets/img/portfolio/BxCoach.png";
import docstream from "assets/img/portfolio/docstream.png";
import fro from "assets/img/portfolio/Fro-Community.png";
import LWB from "assets/img/portfolio/LWB.png";
import algodex from "assets/img/portfolio/algodex.png";
import T7event from "assets/img/portfolio/T7event.png";
import Jots from "assets/img/portfolio/jots.ng.png";
import laudemy from "assets/img/portfolio/Laudemy.png";

const navCard = (
  img: any,
  name: string,
  overview: string,
  role: string,
  url: string
) => {
  return (
    <div className="portfolio-box shadow-lg">
      <img src={img} alt="" />
      <div className="details-wrap">
        <div className="name mb-4">{name}</div>
        <div className="overview">
          <p className="title">Overview</p>
          <p className="text-white-200">{overview}</p>
        </div>
        <div className="overview mb-4">
          <p className="title">Role</p>
          <p className="text-white-200">{role}</p>
        </div>
        <a href={url} target="_blank" rel="noreferrer">
          <button className="btn btn-primary btn-md">View Project</button>
        </a>
      </div>
    </div>
  );
};

export const Portfolio = () => {
  return (
    <div className="container" id="portfolio">
      <div className="text-center title" style={{ marginBlock: "7rem" }}>
        Recent Projects
      </div>
      <div className="m-5 portfolio-page">
        {navCard(
          Jots,
          "JOTS NG",
          "A mobile application that helps its users practice for JAMB Examinations with an up to date past question and answers.",
          "Co-founder",
          "https://play.google.com/store/apps/details?id=com.PeterOla.jambites&hl=en_US&gl=US"
        )}

        {navCard(
          laudemy,
          "LAUDEMY",
          "A mobile application that was built for LAUTECH 100 level students, it helps its users practice with previous years questions and they can measure how far they've come and compete with other level mates",
          "Co-founder",
          "https://play.google.com/store/apps/details?id=com.hollainc.laudemy&hl=en&gl=US"
        )}
        {navCard(
          T7event,
          "Triple7Events",
          "An event management system which eventually we turned to a general lifestyle management system. It was born out of the belief that we should all have the opportunity to buy, sell or resell tickets without having to pay a booking fee. This app also offers online store management and fuel station management system.",
          "Frontend Engineer",
          "https://www.triple7events.com/"
        )}
        {navCard(
          algodex,
          "Algodex",
          "Algodex is a highly decentralized marketplace which has its orderbook completely on the Algorand blockchain itself.",
          "Frontend Engineer",
          "https://about.algodex.com/"
        )}
        {navCard(
          docstream,
          "Docstream",
          "A document management system focused on streamlining documents & correspondence. It helps tackle correspondence challenges, whether you’re an individual, business, or government staff",
          "Frontend Engineer",
          "https://www.docstream.ng/"
        )}

        {navCard(
          bixoli,
          "Bixoli",
          "A mobile professional application that brings convenience to the user's doorstep with the help of registered online professionals. The users have the freedom and safety to choose variety of services at their own satisfaction.",
          "Frontend Engineer",
          "https://bixoli.com/"
        )}
        {navCard(
          fro,
          "Froclub",
          "A social networking platform solely for celebrating the diverse black community.",
          "Frontend Engineer",
          "https://froclub-dashboard.onrender.com/"
        )}

        {/* {navCard(
          bxCoach,
          `BXCoach`,
          "A behavior coaching agency app that helps each child learn skills necessary to regulate their emotions, modify behaviors, and achieve their goals. Built with an internal GPS system that provides a location for each interaction thereby making it a crisis support solution.",
          `Frontend Engineer`,
          "https://bxcoach.team/"
        )} */}
        <p className="more-to-come my-5 text-center">
          And many more exciting projects...😊
        </p>
      </div>
    </div>
  );
};

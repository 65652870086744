import React from "react";
import { Icon } from "@iconify/react";

export const Footer = () => {
  return (
    <div className="py-5 d-flex justify-content-center align-items-center">
      Made with
      <span className="mx-2">
        <Icon icon="noto:heart-on-fire" />
      </span>
      by Adesola.
    </div>
  );
};

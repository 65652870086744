import React from "react";
import { Portfolio } from "components/portfolio";
import { HomeHero } from "components/Hero";
import { Footer } from "components/footer";
import { Contact } from "components/contact";

export const HomePage = () => {
  return (
    <section className="home-page">
      <HomeHero />
      <Portfolio />
      <Contact isHero={false} isHome={true}/>
      <Footer />
    </section>
  );
};

import React from "react";
import "assets/scss/App.scss";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { HomePage } from "pages/home";
import { AboutPage } from "pages/about";

function App() {
  return (
    <>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </>
  );
}

function Main() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </>
  );
}

export default App;

import React from "react";
import { Icon } from "@iconify/react";
import "./contact.scope.scss";

export const Contact = ({ isHero, isHome }) => {
  return (
    <>
      <div className={`contact-wrapper ${!isHero && "fixed"} ${isHome && "home-page"}`}>
        <div className={`contact-links-box ${isHero && "rotate"}`}>
          <div className="line mb-4"></div>
          <a
            href="https://www.linkedin.com/in/agun-adesola/"
            target="_blank"
            rel="noreferrer"
            className="contact-link mb-4"
          >
            <Icon icon="entypo-social:linkedin" />
          </a>
          <a
            href="https://github.com/treasurechic"
            target="_blank"
            rel="noreferrer"
            className="contact-link mb-4"
          >
            <Icon icon="icomoon-free:github" />
          </a>
          <a
            href="mailto:agunadesola@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="contact-link mb-4"
          >
            <Icon icon="fluent:mail-24-regular" />
          </a>
          <a
            href="https://twitter.com/treasurechic2"
            target="_blank"
            rel="noreferrer"
            className="contact-link mb-4"
          >
            <Icon icon="feather:twitter" />
          </a>
          <a
            href="https://www.instagram.com/treasurechic_/"
            target="_blank"
            rel="noreferrer"
            className="contact-link mb-4"
          >
            <Icon icon="akar-icons:instagram-fill" />
          </a>
        </div>
      </div>
    </>
  );
};

import React, { useState } from "react";
import { Contact } from "components/contact";
import "./styles.scss";
import logo from "assets/img/logo.svg";
import { Link } from "react-router-dom";
import banner from "assets/img/banner2.jpg";

export const HomeHero = () => {
  const [showContact, setShowContact] = useState(false);
  return (
    <section className="hero">
      <div className="container">
        <img src={logo} alt="" className="logo" />

        <div className="row my-5">
          <div className="col-lg-6 col-md-8 col-12 my-auto">
            <h2 className="title">
              I design easy-to-use <br /> software & mentor budding developers.
            </h2>
            <Link to="/about">
              <button className="btn btn-primary btn-md">About me</button>
            </Link>
            <div className="hero-contact">
              <Contact isHero={true} isHome={false}/>
            </div>
          </div>
          <div className="col-lg-5 col-md-9 col-12 ms-auto mb-5">
            <div className="banner-img">
              <div className="top-overlay"></div>
              <img src={banner} alt="" className="ing" />
              <div className="bottom-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
